.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.241);
  z-index: 9;
  font-size: 54px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
}
