.jobpost-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.job-description {
  line-height: 1.5;
}

a.job-btn.success.sticky {
  width: 200px;
  position: fixed;
  top: 50px;
  right: 100px;
}
