button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  padding: 0px;
}
.on {
  color: gold;
}
.off {
  color: #ffffff;
}

.star {
  font-size: 18px;
  padding: 0;
  margin: 0;
  line-height: 0;
}

.filter-panel {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 100%;
}

#remote-input {
  margin-right: 5px;
}

hr {
  width: 100%;
  border-top: 2px solid;
}

h4 {
  font-size: 20px;
}
