.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.agency-table {
  width: 70%;
}

.agency-btn {
  width: 200px;
  position: absolute;
  right: 0;
  top: 15px;
  margin-right: 30px;
}

.agency-h1 {
  margin: 50px 0;
}

.view-apps-btn {
  position: absolute;
  top: 70px;
}
