h1 {
  margin-top: 20px;
  text-align: center;
}

.job-container {
  display: flex;
  justify-content: space-evenly;
  width: 70%;
  flex-wrap: wrap;
}

.filter-container {
  position: sticky;
  top: 30px;
  display: flex;
  margin-top: 20px;
  width: 30%;
  margin-right: 50px;
  margin-left: 50px;
  height: 80vh;
  background-color: rgb(239, 239, 239);
  height: 600px;
  box-shadow: 5px 5px 5px silver;
}

.job-view {
  display: flex;
  justify-content: space-between;
  position: relative;
}
