.form-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30vh;
}

.login-element {
  display: flex;
  min-width: 60%;
  justify-content: center;
  padding: 5px;
}

.nowrap {
  width: 90px;
  margin-right: 5px;
  font-weight: bold;
}

.form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.form-container:first-of-type {
  border-right: solid black 3px;
}

.login-btn {
  background-color: black;
  letter-spacing: 1.5px;
  color: whitesmoke;
  width: 50%;
  border-radius: 10px;
  font-size: 16px;
  padding: 7px;
  font-weight: bold;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all ease 0.3s;
}

.login-btn:first-of-type {
  margin-bottom: 25px;
}

.login-btn:hover {
  background-color: rgb(23, 23, 23);
  box-shadow: 0px 15px 20px rgba(0, 13, 26, 0.4);
  color: #fff;
  transform: translateY(-5px);
}

.login-btn:disabled {
  background-color: gray;
  color: whitesmoke;
}

.login-btn.google {
  background-color: #4285f4;
}

.login-btn.google:hover {
  background-color: #3873d2;
}
