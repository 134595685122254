.contact-container {
  margin-top: 20px;
}

.hero-container {
  color: white;
  background-image: url(../../public/blue-background-image.jpeg);
  background-position: center;
  height: 300px;
  display: flex;
  padding-left: 20%;
  justify-content: flex-start;
  align-items: center;
}

.hero-container h1 {
  font-size: 60px;
}

.contact-info-container {
  margin-top: 20px;
  padding-left: 20%;
}

.contact-info-container p {
  line-height: 1;
}

.contact-info-container h3 {
  margin-top: 30px;
}
