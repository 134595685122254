.success-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h2 {
  margin-bottom: 30px;
}

.return-btn {
  display: inline-block;
  background-color: rgb(77, 93, 116);
  letter-spacing: 1.5px;
  color: whitesmoke;
  border-radius: 10px;
  font-size: 16px;
  padding: 10px;
  font-weight: bold;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.348);
  cursor: pointer;
  transition: all ease 0.3s;
  text-decoration: none;
  text-align: center;
  margin-top: 20px;
}

.return-btn:hover {
  color: #fff;
  transform: translateY(-5px);
  background-color: rgb(16, 64, 132);
}
