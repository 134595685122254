.view-job-button,
.edit-job-button,
.remove-job-button {
  width: 40px;
}

.posted-btn {
  margin: 3px;
  margin: 3px;
  display: inline-block;
  background-color: rgb(29, 123, 255);
  letter-spacing: 1.5px;
  color: whitesmoke;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 800;
  padding: 6px 12px;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.348);
  cursor: pointer;
  transition: all ease 0.3s;
  text-decoration: none;
}

.view-job-button:hover,
.edit-job-button:hover,
.remove-job-button:hover {
  color: whitesmoke;
}

.view-job-button {
  background-color: green;
}
.edit-job-button {
  background-color: darkgray;
}
.remove-job-button {
  background-color: red;
}

.view-job-button:hover,
.edit-job-button:hover,
.remove-job-button:hover {
  color: whitesmoke;
}

.view-job-button:hover {
  background-color: darkgreen;
}
.edit-job-button:hover {
  background-color: rgb(128, 128, 128);
}
.remove-job-button:hover {
  background-color: darkred;
}

.applicant-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.applicant-btn {
  width: auto;
  margin: 3px;
  display: inline-block;
  background-color: rgb(29, 123, 255);
  letter-spacing: 1.5px;
  color: whitesmoke;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  padding: 7px;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.348);
  cursor: pointer;
  transition: all ease 0.3s;
  text-decoration: none;
}

.applicant-btn:hover {
  background-color: rgb(15, 66, 137);
  color: white;
}

.applicant-col {
  height: 100%;
}

h3 {
  font-size: 24px;
}
