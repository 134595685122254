.navbar {
  background-color: #ffffff;
  height: 80px;
  display: flex;
  padding-right: 10px;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 9;
  margin-left: -18px;
}

.seafwa-container {
  z-index: 99;
  margin: 2px auto;
  display: flex;
  flex-direction: column;
}

.seafwa-logo-container {
  width: 80%;
  margin: 0 auto;
  height: 85px;
  display: flex;
  justify-content: space-between;
}

.seafwa-nav-container {
  position: relative;
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
}

.seafwa-navbar {
  width: 80%;
  margin: auto;
  justify-content: flex-start;
  display: flex;
}

.seafwa-menu-item {
  position: relative;
  text-decoration: none;
  color: #012c3a;
  background-color: transparent;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: unset;
  font-weight: 700;
  padding: 0.75rem 1.25rem;
}

.seafwa-menu-item:hover {
  color: initial;
}

.dropdown::before {
  background-image: url(../../public/ico-arrow.png);
  content: "";
  margin-top: -0.1rem;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  /* margin-top: -0.3125rem; */
  width: 0.44rem;
  height: 0.44rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
}

.seafwa-logo {
  width: 200px;
}

.minrc-title-container {
  width: 60%;
  margin: 30px auto 30px 10%;
}

.minrc-title-container h1 {
  color: #2c2c2c;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  border-bottom: none;
  font-family: "Merriweather", serif;
  text-align: left;
}

.minrc-menu-container {
  width: 80%;
  margin: auto;
}

.minrc-menu-item {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #282d8b;
  text-decoration: none;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 6px 25px;
  margin-top: 5px;
  font-weight: 700;
  margin-right: 6px;
  line-height: 1.428571429;
  border: 1px solid transparent;
}

.minrc-menu-item:hover {
  border-color: #eeeeee #eeeeee #ddd;
  color: white;
}

.minrc-menu-item.active {
  background: transparent;
  border: 2px solid #282d8b;
  color: #282d8b;
  margin-left: 0;
}

.minrc-menu-item.active {
  cursor: default;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: #000;
}

.nav-menu {
  background-color: #ffffff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 450ms;
  z-index: 9;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a,
.nav-text button {
  text-decoration: none;
  color: #151414;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover,
.nav-text button:hover {
  background-color: #000000;
  color: #fff;
}

.nav-menu-items {
  width: 100%;
  padding-left: 0;
}

.navbar-toggle {
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}
