.main-container,
.login-container,
.applicant-login,
.agency-login {
  display: flex;
  justify-content: center;
}

.main-container {
  flex-direction: column;
  align-items: center;
}

.title-container {
  text-align: center;
}

.login-container {
  justify-content: space-around;
  min-width: 60%;
}
