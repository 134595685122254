@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a.job-btn {
  text-align: center;
  text-decoration: none;
}

.job-btn {
  display: inline-block;
  background-color: rgb(29, 123, 255);
  letter-spacing: 1.5px;
  color: whitesmoke;
  width: 42%;
  border-radius: 10px;
  font-size: 16px;
  padding: 7px;
  font-weight: bold;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.348);
  cursor: pointer;
  transition: all ease 0.3s;
}

.job-btn:hover {
  background-color: rgb(26, 154, 218);
  box-shadow: 0px 15px 20px rgba(29, 142, 255, 0.4);
  color: #fff;
  transform: translateY(-5px);
}

.job-btn:disabled {
  background-color: gray;
  color: whitesmoke;
}

.job-btn.success {
  background-color: rgb(25, 135, 84);
}
