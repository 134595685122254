.job-form-container {
  margin-top: 30px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-job-form {
  padding-bottom: 100px;
  width: 35%;
  min-width: 300px;
}

.new-job-h1 {
  margin-bottom: 30px;
}

.text-input {
  margin-top: 20px;
}
