a.job-btn {
  text-align: center;
  text-decoration: none;
}

.job-btn {
  display: inline-block;
  background-color: rgb(29, 123, 255);
  letter-spacing: 1.5px;
  color: whitesmoke;
  width: 42%;
  border-radius: 10px;
  font-size: 16px;
  padding: 7px;
  font-weight: bold;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.348);
  cursor: pointer;
  transition: all ease 0.3s;
}

.job-btn:hover {
  color: #fff;
  transform: translateY(-5px);
}

.job-btn:disabled {
  background-color: gray;
  color: whitesmoke;
}

.job-btn.success {
  background-color: rgb(25, 135, 84);
}

.job-card {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: whitesmoke;
  box-shadow: 5px 5px 5px silver;
  padding: 20px 20px 0 20px;
  margin: 20px;
  height: 200px;
  position: relative;
  transition: all ease 0.5s;
  overflow: hidden;
}

.job-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 220px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0.8) 75%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 1;
  opacity: 1;
  animation: fadeIn 0.5s;
}

.location-pin {
  margin-left: 0;
}

.due-date {
  color: blue;
  align-self: flex-start;
  font-weight: bold;
  font-size: 12px;
}

.job-title {
  letter-spacing: 1px;
}

.chevron {
  text-align: center;
  position: absolute;
  bottom: 0;
  font-size: 24px;
  justify-self: flex-end;
  margin-top: 15px;
  transition: all ease 0.5s;
  cursor: pointer;
  width: 100%;
  z-index: 2;
}

.expanded.chevron {
  transform-origin: 50%;
  transform: rotate(180deg);
}

.card-btn-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
}

.show-full {
  height: auto;
  padding-bottom: 30px;
}

.show-full::after {
  animation: fadeOut 0.5s;
  opacity: 0;
  height: 0;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    height: 220px;
  }
  100% {
    opacity: 0;
    height: 375px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    height: 375px;
  }
  100% {
    opacity: 1;
    height: 220px;
  }
}

.job-star {
  color: gold;
  font-size: 16px;
  line-height: 0;
  margin: 0;
  padding-bottom: 0;
}

.job-keyword-cont {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.job-keyword {
  background: rgb(191, 242, 191);
  margin: 2px;
  padding: 3px;
  border: 1px solid black;
  font-size: 12px;
}
