.new-applicant-form {
  display: flex;
  flex-direction: column;
}

.form-container {
  margin-top: 30px;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

input,
.select-input {
  margin: 10px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.select-input {
  text-align: left;
  padding: 0;
  height: 32px;
  border-radius: 3px;
  border-color: black !important;
}

.submit-btn {
  width: 100%;
  margin-top: 20px;
  background-color: green;
  color: whitesmoke;
}

.submit-btn:hover {
  background-color: rgb(0, 189, 0);
  color: whitesmoke;
}
