.seafwa-footer {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #2c2c2c;
  color: white;
  margin-top: 50px;
}

.seafwa-footer h4 {
  font-size: 20px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 700;
  line-height: 1.428571429;
  text-transform: uppercase;
}

.footer-nav-container {
  margin: 55px;
  display: flex;
  flex-direction: column;
}

.seafwa-footer-link {
  text-decoration: none;
  display: inline-block;
  line-height: 15px;
  padding: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 550;
  margin: 4px;
}

.seafwa-footer-link:hover {
  color: #fff;
}
