.applicant-view-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  margin-left: auto;
}

.applicant-button {
  width: auto;
  margin: 3px;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.button-container {
  margin-top: 100px;
  display: flex;
  width: 300px;
  justify-content: space-around;
}

.applicant-skill {
  background: rgb(191, 242, 191);
  margin: 2px;
  padding: 3px;
  border: 1px solid black;
  font-size: 22px;
}

.skill-container {
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 400px;
}

.return-btn {
  display: inline-block;
  background-color: rgb(77, 93, 116);
  letter-spacing: 1.5px;
  color: whitesmoke;
  border-radius: 10px;
  font-size: 16px;
  padding: 10px;
  font-weight: bold;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.348);
  cursor: pointer;
  transition: all ease 0.3s;
  text-decoration: none;
  text-align: center;
  margin-top: 20px;
  margin-left: 20px;
}

.return-btn:hover {
  color: #fff;
  transform: translateY(-5px);
  background-color: rgb(16, 64, 132);
}
